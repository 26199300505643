import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';

type Props = {
    _cb: any
}

const textPosition = (position: string) => {
    const [ horizontal, vertical] = position.split('-');
    let top = '50%';
    let left = '50%';
    if (vertical === 'top') {
        top = '50%';
    } else if (vertical === 'bottom') {
        top = '80%';
    }
    if (horizontal === 'left') {
        left = '30%';
    } else if (horizontal === 'right') {
        left = '75%';
    }
    return { top, left };
}

const Slider = (props: Props) => {

    const _slides = [
        {
            name: 'Our world is changing',
            url: './slider_opt/resized/slider1_.png',
            textPosition: 'right-top',
            textColor: 'white',
            highlightColor: 'black',
            text: 'By 2025, $2.8 Trillion will drive workplace tech, shifting us toward a future where machines challenge human intelligence.',
        },
        {
            name: 'New skills are required',
            url: './slider_opt/resized/slider-2-1.png',
            textPosition: 'left-middle',
            textColor: '',
            highlightColor: '',
            text: 'The future demands new skills: AI literacy, critical thinking, adaptability. Start building yours today.'
        },
        {
            name: 'Join the AI Club Today!',
            url: './slider_opt/resized/join-slide.png',
            textPosition: 'middle-middle',
            textColor: '',
            highlightColor: '',
            text: 'Join the Codeplant AI Club to master the tech shaping our future. Don’t just watch, lead the change.'
        }
    ];
    

    const _slideTemplate = (slide: any) => {
        const { top, left } = textPosition(slide.textPosition);

        return (
            <div className='slides'>
                <img src={slide.url} style={{width: '100%', borderRadius: '10px'}}/>
                <div className="overlay-text" style={{top: `${top}`, left: `${left}`, padding: 10, borderRadius: '15px', backgroundColor: 'rgb(0,0,0, .75)', maxWidth: '450px'}}>
                    
                   <h5>{slide.name}</h5> 
                    <p style={{fontSize: '16px', color: 'white', fontWeight: 'normal'}}>{slide.text}</p>
                    <Button onClick={()=>{
                        props._cb(slide.link)
                    }}  
                    label="Join the AI Club"  
                    icon="pi pi-sparkles" 
                    style={{backgroundColor: 'green', color: 'white', border: '1px solid white', margin: '40px'}} />
                    
                </div>

            </div>
        )
    }
  return (
            <Carousel  page={1} autoplayInterval={10000}  showIndicators={true} showNavigators={false}  circular  value={_slides} numScroll={1} numVisible={1}  itemTemplate={_slideTemplate} style={{height:'500px'}}/>
        )
}

export default Slider