import React from 'react';
import { Card } from 'primereact/card';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

interface UserProfile {
  id: string;
  email: string;
  name: string;
  picture: string;
  profile: string;
  createdAt: number;
  lastLogin: number;
}

interface Competency {
  skill: string;
  level: number;
}

const parseProfile = (profileText: string): { competencies: Competency[], learningPreferences: string[] } => {
  const competencies: Competency[] = [];
  const learningPreferences: string[] = [];
  
  const lines = profileText.split('\n');
  
  lines.forEach(line => {
    // Parse competencies
    if (line.includes(':')) {
      const match = line.match(/\*\*(.*?):\*\* (.*)/);
      if (match) {
        const skill = match[1];
        const level = match[2];
        let levelValue = 0;
        
        switch (level.trim().toLowerCase()) {
          case 'strong': levelValue = 5; break;
          case 'moderate': levelValue = 3; break;
          case 'weak': levelValue = 1; break;
          default: levelValue = 0;
        }
        
        competencies.push({ skill, level: levelValue });
      }
    }
    
    // Parse learning preferences
    if (line.includes('prefer learning through')) {
      const prefs = line.split('through')[1].split(',').map(p => 
        p.replace('.', '').trim()
      );
      learningPreferences.push(...prefs);
    }
  });
  
  return { competencies, learningPreferences };
};

const UserProfileChart: React.FC<{ user: UserProfile }> = ({ user }) => {
  const { competencies, learningPreferences } = parseProfile(user.profile || '');
  
  const chartData = {
    labels: competencies.map(c => c.skill),
    datasets: [
      {
        label: 'Competency Level',
        data: competencies.map(c => c.level),
        backgroundColor: 'rgba(17, 140, 56, 0.2)',
        borderColor: 'rgba(17, 140, 56, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      r: {
        min: 0,
        max: 5,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <Card className="profile-chart">
      <div className="grid">
        <div className="col-12 md:col-6">
          <h3>Skills Assessment</h3>
          <div style={{ maxWidth: '400px', margin: 'auto' }}>
            <Radar data={chartData} options={chartOptions} />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <h3>Learning Preferences</h3>
          <ul className="list-none p-0 m-0">
            {learningPreferences.map((pref, index) => (
              <li key={index} className="mb-2">
                <i className="pi pi-check-circle mr-2" style={{ color: '#118c38' }}></i>
                {pref}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  );
};

export default UserProfileChart;