import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import UserProfileChart from './UserProfileChart';

interface User {
  id: string;
  email: string;
  name: string;
  picture: string;
  createdAt: number;
  lastLogin: number;
  profile: string;
  sub: string;
}

const UserProfiles: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const response = await fetch("/api/admin/users");
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        // Filter users with profile data
        const usersWithProfiles = data.filter((user: User) => user.profile);
        setUsers(usersWithProfiles);
      } catch (error) {
        console.error("Error loading users:", error);
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, []);

  if (loading) {
    return <div>Loading profiles...</div>;
  }

  return (
    <div className="user-profiles">
      {users.map(user => (
        <div key={user.id} className="mb-4">
          <Card>
            <div className="flex align-items-center mb-3">
              <img 
                src={user.picture} 
                alt={user.name} 
                style={{ 
                  width: '50px', 
                  height: '50px', 
                  borderRadius: '50%',
                  marginRight: '1rem'
                }} 
              />
              <h2 className="m-0">{user.name}</h2>
            </div>
            <UserProfileChart user={user} />
          </Card>
        </div>
      ))}
    </div>
  );
};

export default UserProfiles;