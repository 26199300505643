import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

interface User {
  id: string;
  email: string;
  name: string;
  picture: string;
  createdAt: number;
  lastLogin: number;
  profile: string;
  sub: string;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [userForm, setUserForm] = useState<Partial<User>>({});
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  // Fetch users when the component mounts
  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/admin/users", { method: "GET" });
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data);
    } catch (error: any) {
      console.error("Error loading users:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const openNewUserDialog = () => {
    setUserForm({});
    setIsEditMode(false);
    setDisplayDialog(true);
  };

  const openEditUserDialog = (user: User) => {
    setUserForm(user);
    setIsEditMode(true);
    setDisplayDialog(true);
  };

  const saveUser = async () => {
    try {
      let response;
      if (isEditMode && userForm.id) {
        // Update an existing user (PUT)
        response = await fetch(`/api/admin/users/${userForm.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userForm),
        });
      } else {
        // Create a new user (POST)
        response = await fetch("/api/admin/users", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userForm),
        });
      }
      if (!response.ok) {
        throw new Error("Failed to save user");
      }
      // Refresh the list after saving
      await loadUsers();
      setDisplayDialog(false);
    } catch (error: any) {
      console.error("Error saving user:", error.message);
    }
  };

  const deleteUser = async (userId: string) => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }
    try {
      const response = await fetch(`/api/admin/users/${userId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete user");
      }
      // Refresh the list after deletion
      await loadUsers();
    } catch (error: any) {
      console.error("Error deleting user:", error.message);
    }
  };

  // Helper to format timestamps
  const formatDate = (timestamp: number) => {
    if (!timestamp) return "";
    return new Date(timestamp).toLocaleString();
  };

  // Body templates for DataTable columns
  const pictureBodyTemplate = (rowData: User) => {
    return <img src={rowData.picture} alt="User" width="50" height="50" style={{ borderRadius: "50%" }} />;
  };

  const createdAtBodyTemplate = (rowData: User) => {
    return formatDate(rowData.createdAt);
  };

  const lastLoginBodyTemplate = (rowData: User) => {
    return formatDate(rowData.lastLogin);
  };

  // Actions column – can be extended with new buttons/actions
  const actionBodyTemplate = (rowData: User) => {
    return (
      <>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => openEditUserDialog(rowData)} tooltip="Edit User" />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteUser(rowData.id)} tooltip="Delete User" />
      </>
    );
  };

  return (
    <div className="user-management">
      <div className="card">
        {/* <h2>User Management</h2> */}
        {/* <Button label="New User" icon="pi pi-plus" onClick={openNewUserDialog} /> */}
        <DataTable value={users} loading={loading} paginator rows={10} className="mt-3" responsiveLayout="scroll">
          <Column header="Picture" body={pictureBodyTemplate} />
          <Column field="name" header="Name" sortable />
          <Column field="email" header="Email" sortable />
          <Column header="Created At" body={createdAtBodyTemplate} sortable />
          <Column header="Last Login" body={lastLoginBodyTemplate} sortable />
          <Column header="Actions" body={actionBodyTemplate} />
        </DataTable>
      </div>

      <Dialog header={isEditMode ? "Edit User" : "New User"} visible={displayDialog} style={{ width: "500px" }} modal onHide={() => setDisplayDialog(false)}>
        <div className="p-fluid">
          <div className="field">
            <label htmlFor="name">Name</label>
            <InputText id="name" value={userForm.name || ""} onChange={(e) => setUserForm({ ...userForm, name: e.target.value })} autoFocus />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <InputText id="email" value={userForm.email || ""} onChange={(e) => setUserForm({ ...userForm, email: e.target.value })} />
          </div>
          <div className="field">
            <label htmlFor="picture">Picture URL</label>
            <InputText id="picture" value={userForm.picture || ""} onChange={(e) => setUserForm({ ...userForm, picture: e.target.value })} />
          </div>
          <div className="field">
            <label htmlFor="profile">Profile</label>
            <InputTextarea id="profile" value={userForm.profile || ""} onChange={(e) => setUserForm({ ...userForm, profile: e.target.value })} rows={5} />
          </div>
          <div className="field">
            <label htmlFor="sub">Sub (Read-only)</label>
            <InputText id="sub" value={userForm.sub || ""} disabled />
          </div>
        </div>
        <div className="p-dialog-footer">
          <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplayDialog(false)} className="p-button-text" />
          <Button label="Save" icon="pi pi-check" onClick={saveUser} autoFocus />
        </div>
      </Dialog>
    </div>
  );
};

export default UserManagement;
